@import "variables";
@import "mixins";

.tab-bar{
    position: fixed;

    // width: 100px;
    padding: 10px;
    background-color: $black;
    z-index: 1000;
    left: 0;

    //padding: 10px;
    @include mQ-min(992px){
        height: 100vh;

        top: 0;
    }
    @include mQ-max(992px){
        //height: 50px;
        width: 100vw;
        bottom: 0;

    }
    *{
        color:$white;

    }
    .tab-logo{
        position: fixed;
        top:0;
        left: 0;
        text-align: center;
        display: flex;
        justify-content: center;
        align-self: center;
    }

    .tabs{
        height: 45%;
        width: 50px;
        align-items: center;
        justify-content: space-evenly;
        margin: auto 10px;
        @include mQ-max(992px){
            //height: 50px;
            width: 100%;
            margin: auto;

        }
        a{
            text-decoration: none;
            *{
                transition: all ease-in 300ms;
                margin:0;
            }
            img{
                width: 40px;
                height: 40px;
                margin: auto;
                padding: 5px;

            }
            .inactive{
                display: flex;
            }
            .active{
                display: none;
            }
            p{
                font-size: 13px;
                color: $light_grey;
            }
            &.active{
                img{
                    padding: 3px;
                }
                .inactive{
                    display: none;
                }
                .active{
                    display: flex;
                }
                p{
                    color:$green;
                    font-size: 14px;
                }
            }
            &:hover{
                img{
                    padding: 0;

                }
                p{
                    font-size: 15px;
                    color:$hover_green;
                }
            }
            @include mQ-max(992px){
                img{
                    width: 35px;
                    height: 35px;
                }
                p{
                    font-size: 12px;
                }
                &:hover,&.active{
                    p{
                        font-size: 13px;
                    }
                }
            }
        }
    }
}
