@import "variables";

.App {
  text-align: center;
  //background-color: $dark_grey;
  width: 100%;
  height: 100%;
  .main-bg{
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: -10;
    background-color: $dark_grey;
  }

}

