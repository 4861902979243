@import "scss/variables.scss";

body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  margin:0;
  padding: 0;
  box-sizing: border-box;

  font-family: 'Poppins','Roboto';
}

*::-webkit-scrollbar {
  width: 10px;
  height: 100%;
  background-color: $black;
  //border-radius: 15px;
}
*::-webkit-scrollbar-thumb {
  background-color: $hover_green;
  border-radius: 15px;
}
*::-webkit-scrollbar:hover {
  background-color: $dark_grey;
}
*::-webkit-scrollbar-thumb:hover {
  background-color: $green;
}