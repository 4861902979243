

@mixin mQ-max($arg) {
  @media screen and (max-width: $arg) {
    @content;
  }
}
@mixin mQ-min($arg) {
  @media screen and (min-width: $arg) {
    @content;
  }
}