@import "variables";
@import 'mixins';

.contents-container{
  //position: relative;
  //height: 100vh;
  //overflow-y: scroll;

  &,*{
    color: $white;
  }
  section{
    height: 100vh;
    margin-left: auto;
    @include mQ-max(992px){
      margin:auto;
    }
    &>div{
      padding: 50px;
      &>*{
        margin-bottom: 55px;
      }
    }
    p{
      font-size: clamp(16pt,2vw,24pt);
      font-weight: 100;
      text-align: left;
    }
    h1{
      font-size: clamp(30pt,4.5vw,60pt);
      color: $green;
      font-weight: bold;
      text-align: left;
    }
    &#home{

      a{
        text-decoration: none;
        background-color: $green;
        color: $black;
        font-size: 20px;
        padding: 10px 40px;
        border:0;
        border-radius: 6px;
        outline: none;
        transition: all ease-in 300ms;
        height:60px;
        width: 250px;
        display: flex;
        align-items: center;
        justify-content: center;
        //width: 100%;
        &:hover,&:active{
          background-color: #fff0;
          color:$green;
          border:2px solid ;
          font-weight: 100;
        }
      }
      .home-text{
        img{
          width:80%;
          height: auto;
        }
        p{

        }
      }
      .home-illustrations{
        background: radial-gradient(circle, $dark_green 0%, transparent 40%);
        .ill{
          *{
            width: 100%;
            height: auto;
          }
        }
        //padding: 0;
        //.ill{
        //  overflow: none;
        //  #laptop{
        //    animation: laptop 3000ms linear infinite;
        //    #laptop-object{
        //
        //    }
        //    #laptop-icon{
        //    }
        //
        //  }
        //  @keyframes laptop {
        //    0%{
        //        //transform: rotate3d(0,0,0,0);
        //      transform: rotate(0deg);
        //    }
        //    20%{
        //        //transform: rotate3d(0.3,1,0,180deg);
        //      transform: rotate(20deg);
        //    }
        //    80%{
        //      transform: rotate(-20deg);
        //    }
        //    100%{
        //        //transform: rotate3d(0,0,0,0);
        //      transform: rotate(0deg);
        //    }
        //  }
        //}
      }
    }
    &#about{
      .about-illustrations{
        *{
          transition: opacity linear 500ms;
        }
        .profile{
          margin-top: 200px;
          opacity: 0;
          text-decoration: none;
          display: block;
          &>div{
            margin: 20px;
          }
          .profile-img{
            width: 100px;
            height: 100px;
            border:1px solid $green;
            overflow:hidden;
            background-color: $grey;
            margin: auto;
            border-radius: 50%;
            box-shadow: 5px 5px 10px 5px $black;
            img{
              width: 100%;
              height: 100%;
            }
          }
          .profile-info{
            h3{
              color:$green;
              font-weight: bold;

            }
            p{
              font-size: 18px;
              text-align: center;
            }
          }
          &:hover{
            .profile-img{
              width: 105px;
              height: 105px;
              box-shadow: 7px 7px 20px 5px $black ;
            }
            h3{
              font-size: 30px;
            }
          }
        }

        //.left,.right{
        //  margin: 100px;
        //}
      }
      .about-illustrations.show{
        .profile{
          opacity: 1;
          margin:0px;
        }
        .left{
          .profile{
            margin-bottom: 100px;
          }
        }
        .right{
          .profile{
            margin-top: 100px;
          }
        }
      }
    }
    &#skills{

    }
    &#contacts{

      .contacts-form{

        form{
          input,textarea,button{
            background-color: $dark_grey;
            border: 1px solid $green;
            //color: $green;
            width:100%;
            min-height: 50px;
            border-radius: 10px;
            margin-bottom: 30px;
            padding: 8px;
            color: $white;

            &::placeholder{
              color: $light_grey;
            }
            &:focus{
              outline: none;
              background-color: lighten($dark_grey,5);
              border-color: $active_green;
            }
          }
          textarea{
            height: 150px;
          }
          button{
            width: 50%;
            margin:auto;
            transition: all ease-in 300ms;
            color:$green;
            &:hover{
              color: $black;
              background-color: $hover_green;
              border: 0;
            }
            &:active{
              color: $black;
              background-color: $active_green;
              border: 0;
            }
          }

          div{
            position: relative;
            span{
              position: absolute;
              right: 0;
              top: 0;
              margin: 10px;
              display: none;
            }
            input.alert,textarea.alert{
              border: 1px solid #dc424f;
              &~span{
                display: flex;
              }
            }
          }
        }
      }
      .contacts-links{
        .links-containter{
          width: fit-content;
        }
          h2{
            //color:$green;
            //font-weight: 700;
          }
          a,span{
            text-decoration: none;
            //width: 65%;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            margin: 15px;
            div{
              width: 30px;
              height: 30px;
              border:1px solid $green;
              overflow:hidden;
              background-color: $grey;
              border-radius: 50%;
              img{
                width: 100%;
                height: auto;
                padding: 5px;
              }
            }
            p{
              margin: 0 15px;
              padding: 0;
              font-size: 20px;
              font-weight: normal;
            }
          }
        a:hover,span:hover{
          div{
            width: 33px;
            height: 33px;

          }
          p{
            font-size: 21px;
          }
        }
      }
    }
  }
}